import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "@pages/home";
import Navbar from "@components/navbar";
import BlackTop from "@components/blackTop";
import Search from "@pages/search";
import Loader from "@components/loader";
import Register from "@pages/register";
import Login from "@pages/login";
import Footer from "@components/footer";
import Otp from "@pages/otp";
import Product from "@pages/products";
import ProductDetail from "@pages/productDetail";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useGetTokenMutation, useTokenUpdateMutation } from "@services/AllApi";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

function App() {
  const opensearch = useSelector((state) => state.HomeSlice.opensearch);
  const loader = useSelector((state) => state.HomeSlice.loader);

  const [tokenUpdate] = useTokenUpdateMutation();
  const [getToken, { data: getTokenData, isSuccess: getTokenSuc }] = useGetTokenMutation();

  // Function to decrypt values
  const decryptValue = (encryptedValue) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption error:", error);
      return null;
    }
  };

  // Function to handle token update scheduling
  const scheduleTokenUpdate = (encryptedTokenExp) => {
    const tokenExp = decryptValue(encryptedTokenExp);
    if (tokenExp) {
      const tokenExpDate = new Date(tokenExp);
      const updateDate = new Date(tokenExpDate);
      updateDate.setDate(updateDate.getDate() - 1); // One day before expiration

      const timeUntilUpdate = updateDate - new Date();
      if (timeUntilUpdate > 0) {
        setTimeout(() => {
          tokenUpdate().then(() => {
            console.log("Token updated successfully.");
          });
        }, timeUntilUpdate);
      }
    }
  };

  useEffect(() => {
    getToken({
      email: "morobolsin@mail.ru",
      password: "bsamu053",
    });
  }, []);


  useEffect(() => {
    if (getTokenSuc && getTokenData) {
      const accessToken = getTokenData.result?.access_token || "";
      const tokenExpiresAt = getTokenData.result?.token_expires_at || "";

      if (accessToken && tokenExpiresAt && SECRET_KEY) {
        try {
          const encryptedAccessToken = CryptoJS.AES.encrypt(accessToken, SECRET_KEY).toString();
          const encryptedTokenExpiresAt = CryptoJS.AES.encrypt(tokenExpiresAt, SECRET_KEY).toString();

          localStorage.setItem("apiToken", encryptedAccessToken);
          localStorage.setItem("apiTokenExp", encryptedTokenExpiresAt);

          scheduleTokenUpdate(encryptedTokenExpiresAt);
        } catch (error) {
          console.error("Encryption error:", error);
        }
      }
    }
  }, [getTokenSuc, getTokenData]);


  useEffect(() => {
    const reloadIfNoToken = () => {
      if (!localStorage.getItem("apiToken")) {
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Delay of 1 second
      }
    };

    reloadIfNoToken();
  }, []);


  return (
    <div className={`App relative`}>
      <BlackTop />
      <Navbar />
      {opensearch && <Search />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/productDetail/:productId" element={<ProductDetail />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loader && <Loader />}
    </div>
  );
}

export default App;
