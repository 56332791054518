import React, { useEffect, useState } from 'react';
import bars from "@assets/icons/bars.svg";
import logo from "@assets/icons/logo.svg";
import heart from "@assets/icons/like.svg";
import box from "@assets/icons/box.svg";
import login from "@assets/icons/login.svg";
import profileIcon from "@assets/icons/profileIcon.png";
import search from "@assets/icons/search.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import Supmenu from '@pages/supmenu';
import TranslationData from "@Store/languageData.json";
import { useGetAllCategoryMutation } from '@services/AllApi';
import { icons } from '@utilits/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setActivesupmenu, setOpensearch, setLoader, setLangData } from '@Slice';

function Navbar() {
  const location = useLocation();
  const dispatch = useDispatch()
  const clientToken = localStorage.getItem("clientToken")
  const apiToken = localStorage.getItem("apiToken")
  const lang = useSelector((state) => state.HomeSlice.lang)
  const langData = useSelector((state) => state.HomeSlice.langData)
  const activresupmenu = useSelector((state) => state.HomeSlice.activresupmenu)

  const [getAllCategory, { data: getAllCategoryData, isLoading: getAllCategoryLoading, isSuccess: getAllCategorySuc }] = useGetAllCategoryMutation();
  const [activemenu, setActivemenu] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const toggleMenu = (menuId) => {
    setActivemenu(menuId === activemenu ? null : menuId);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (apiToken && !getAllCategoryData) {
      getAllCategory({ method: "get_all_categories" });
    }
  }, [getAllCategory, apiToken]);

  useEffect(() => {
    dispatch(setLoader(!getAllCategorySuc));
  }, [getAllCategorySuc, setLoader]);

  useEffect(() => {
    if (lang === "uz") {
      dispatch(setLangData(TranslationData.uz));
    } else if (lang === "ru") {
      dispatch(setLangData(TranslationData.ru));
    } else if (lang === "en") {
      dispatch(setLangData(TranslationData.en));
    }
  }, [lang, setLangData]);

  const NameLangKey = `name_${lang}`;

  const handleNavigate = (item) => {
    toggleMenu(item.id);
    navigate(`/product/${item.id}`);
  };

  return (
    <div className={`container mx-auto ${location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/otp" ? 'hidden' : ''}`}>
      <div className='py-[26px] flex items-center justify-between'>
        <img src={bars} onClick={() => dispatch(setActivesupmenu(!activresupmenu))} alt="Menu" className='cursor-pointer hidden lg:block' />
        <img src={logo} onClick={() => navigate("/")} alt="Logo" className='cursor-pointer' />
        <img onClick={() => setOpenDropdown(true)} src={bars} alt="Menu" className='cursor-pointer block lg:hidden' />
        <div className=' items-center gap-[30px] hidden lg:flex'>
          <img src={heart} alt="Heart" className='cursor-pointer' />
          <img src={box} alt="Box" className='cursor-pointer' />
          <div className='bg-[#E9E9E9] w-[1px] h-[40px]'></div>
          {clientToken ? <img className='w-10 h-10' src={profileIcon} alt="" /> :
            <p onClick={() => navigate("/login")} className='flex items-center text-[20px] gap-[12px] cursor-pointer '>
              <img src={login} alt="Login" /> {langData.login}
            </p>}
        </div>
      </div>
      {activresupmenu && <Supmenu categoryData={getAllCategoryData?.result} />}
      <div className='bg-[rgb(233,233,233)] h-[1px]'></div>
      <div onMouseLeave={() => toggleMenu(null)} className='py-[20px]  items-center justify-between hidden lg:flex'>
        <ul className='flex items-center gap-[50px] text-[18px] leading-[20px]'>
          {
            getAllCategoryData?.result.map((item, index) => (
              item.parent_id === 0 ?
                <div key={index} className="relative">
                  <button onMouseEnter={() => toggleMenu(item.id)} onClick={() => handleNavigate(item)} className='flex items-center gap-3 text-[20px] leading-[30px]'>
                    {item[NameLangKey]}
                    {
                      item.all_children.length > 0 &&
                      <span className={`${activemenu === item.id ? 'rotate-[180deg] duration-300' : " duration-300"}`}>{icons.arrowdownblack}</span>
                    }
                  </button>
                  {activemenu === item.id && item.all_children.length > 0 &&
                    <ul className='grid absolute top-0 bg-white z-30 p-[16px] shadow-2xl rounded-lg mt-8 text-gray-600 text-lg'>
                      <h1 className='text-xl mb-8 font-semibold text-gray-700'>{item[NameLangKey]}</h1>
                      {item.all_children.map((child, childIndex) => (
                        <li
                          key={childIndex}
                          onClick={() => handleNavigate(child)}
                          style={{
                            borderBottom: `${item.all_children.length - 1 === childIndex ? 'none' : '1px solid #EDEEEF'}`,
                            paddingBottom: `${item.all_children.length - 1 === childIndex ? '0' : '16px'}`,
                            marginBottom: `${item.all_children.length - 1 === childIndex ? '0' : '16px'}`
                          }}
                          className='cursor-pointer whitespace-nowrap text-lg text-gray-700'
                        >
                          {child[NameLangKey]}
                        </li>
                      ))}
                    </ul>
                  }
                </div>
                : ""
            ))
          }
        </ul>
        <div className='bg-[#F5F5F5] w-[20%] rounded-[64px] flex items-center justify-between py-[14px] px-[20px]'>
          <input type="text" placeholder={langData.search} className='bg-transparent w-full outline-none' onClick={() => dispatch(setOpensearch(true))} />
          <img className='cursor-pointer' src={search} alt="Search" />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
