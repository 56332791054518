import React, { useEffect, useState } from 'react';
import { useGetAllCategoryMutation, useGetAllColorsMutation, useGetAllProductsMutation } from '@services/AllApi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { icons } from '@utilits/icons';
import { useParams } from 'react-router-dom';
import OurproductMap from '@components/ourProduct/OurproductMap';
import FiltersSm from './FiltersSm';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '@Slice';
import checkSvg from "@assets/icons/check.svg"

const minDistance = 1;

const Product = () => {
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.HomeSlice.lang);
  const langData = useSelector((state) => state.HomeSlice.langData);
  const apiToken = localStorage.getItem("apiToken")

  const [checkedCategories, setCheckedCategories] = useState([Number(productId)]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000000]);

  const [getAllCategory, { data: categoryData, isLoading: categoryLoading, isSuccess: categorySuccess }] = useGetAllCategoryMutation();
  const [getAllProducts, { data: productsData, isLoading: productsLoading, isSuccess: productsSuccess }] = useGetAllProductsMutation();
  const [getAllColors, { data: colorsData, isLoading: colorsLoading, isSuccess: colorsSuccess }] = useGetAllColorsMutation();

  const productsBody = {
    method: "get_all_products",
    params: {
      category_ids: checkedCategories,
      color_ids: selectedColors,
      min_price: priceRange[0],
      max_price: priceRange[1],
    },
  };

  useEffect(() => {
    if (categoryLoading || colorsLoading || productsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [categoryLoading, colorsLoading, productsLoading, dispatch]);

  useEffect(() => {
    if (apiToken && !productsData) {
      getAllProducts(productsBody);
    }
  }, [checkedCategories, selectedColors, productId, getAllProducts]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllProducts(productsBody);
    }, 1000);
    return () => clearTimeout(timer);
  }, [priceRange, getAllProducts]);

  useEffect(() => {
    setCheckedCategories([Number(productId)]);
  }, [productId]);

  useEffect(() => {
    if (apiToken && !categoryData) {
      getAllCategory({ method: "get_all_categories" });
    }
  }, [getAllCategory, apiToken]);

  useEffect(() => {
    if (apiToken && !colorsData) {
      getAllColors({ method: "get_colors" });
    }
  }, [getAllColors, apiToken]);

  useEffect(() => {
    if (colorsSuccess && colorsData?.status) {
      const allColorIds = colorsData.result.map((color) => color.id);
      setSelectedColors(allColorIds);
    }
  }, [colorsSuccess, colorsData]);

  const handleCategoryChange = (id) => {
    setCheckedCategories((prev) => (prev.includes(id) ? prev.filter((catId) => catId !== id) : [...prev, id]));
  };

  const handleColorChange = (id) => {
    setSelectedColors((prev) => (prev.includes(id) ? prev.filter((colorId) => colorId !== id) : [...prev, id]));
  };

  const handlePriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setPriceRange([Math.min(newValue[0], priceRange[1] - minDistance), priceRange[1]]);
    } else {
      setPriceRange([priceRange[0], Math.max(newValue[1], priceRange[0] + minDistance)]);
    }
  };

  const nameLangKey = `name_${lang}`;

  const currency = (number, currency, lang = undefined) =>
    Intl.NumberFormat(lang, { style: 'currency', currency }).format(number);

  return (
    <div className="container mx-auto mt-[62px]">
      <div className="w-full mb-[24px] hidden lg:flex items-center justify-between">
        <h3 className="font-semibold text-[20px]">{langData.Product_types}</h3>
        <div className="flex items-center gap-[20px]">
          <p className="text-[14px] text-[#96999E]">
            {langData.Products_found} <span className="text-[black]">{productsSuccess ? productsData?.result?.products?.length : '0'} {langData.pcs}</span>
          </p>
          {/* <div className="w-[1px] bg-[#A7AAAE] h-[32px]"></div> */}
          {/* <p className="flex items-center justify-between w-[237px]">
            Saralash <span className="cursor-pointer">{icons.arrowdownblack}</span>
          </p> */}
        </div>
      </div>
      <div className="block lg:hidden">
        <FiltersSm />
      </div>
      <div className="flex gap-[38px]">
        <div className="w-[26%] mt-[5px] hidden lg:block">
          <div>
            <ul>
              {categoryData?.result.map(
                (item, index) =>
                  item.parent_id === 0 && (
                    <div key={index}>
                      <li className="flex items-center justify-between mb-[12px]">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="accent-[black] h-4 w-4 rounded-md cursor-default"
                            id={`parent_${item.id}`}
                            checked={checkedCategories.includes(item.id)}
                            onChange={() => handleCategoryChange(item.id)}
                          />
                          <label className="ml-[8px]  font-semibold" htmlFor={`parent_${item.id}`}>
                            {item[nameLangKey]}
                          </label>
                        </div>
                        <p className="font-semibold">({item.product_count})</p>
                      </li>
                      <ul className="ml-[10px] text-[14px]">
                        {item.all_children?.map((child, childIndex) => (
                          <li className="my-[8px] flex items-center justify-between" key={childIndex}>
                            <div className="flex items-center gap-[8px]">
                              <input
                                type="checkbox"
                                className="accent-[black] h-3 w-3 cursor-default"
                                id={`child_${child.id}`}
                                checked={checkedCategories.includes(child.id)}
                                onChange={() => handleCategoryChange(child.id)}
                              />
                              <span>{child[nameLangKey]}</span>
                            </div>
                            <p>({child.product_count})</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
            </ul>
          </div>

          <div className="mb-4 mt-[49px]">
            <h3 className="font-semibold mb-2">{langData.Filter_by_price}</h3>
            <div className="flex items-center gap-[26px] mt-[32px] mb-[24px]">
              <div className="bg-[#EDEEEF] rounded-[8px]">
                <p className="text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold">
                  {currency(priceRange[0], 'UZS').replace('UZS', '').replace('soʻm', '').replace(/,/g, ' ').slice(0, -3).replace('.', ' ')}{' '}

                </p>
              </div>
              <div className="bg-[#EDEEEF] rounded-[8px]">
                <p className="text-[20px] py-[10px] px-[20px] text-[#575B5F] font-semibold">
                  {currency(priceRange[1], 'UZS').replace('UZS', '').replace('soʻm', '').replace(/,/g, ' ').slice(0, -3).replace('.', ' ')}{' '}

                </p>
              </div>
            </div>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              color="black"
              min={0}
              max={1000000}
              step={10}
              disableSwap
            />
          </div>

          <div className="mb-[90px] ">
            <h3 className="font-semibold mb-2">{langData.Colors}</h3>
            <div className="flex flex-col gap-[6px] mt-[14px] flex-wrap">
              {colorsSuccess &&
                colorsData?.result.map((color) => (
                  <div className="flex items-center justify-between" key={color.id}>
                    <button
                      style={{ backgroundColor: color.name_en }}
                      className={`rounded-[8px] w-[48px] h-[48px] flex items-center justify-center cursor-pointer border-2 ${selectedColors.includes(color.id) ? 'border-black' : 'border-gray-500'
                        }`}
                      onClick={() => handleColorChange(color.id)}
                    >
                      {selectedColors.includes(color.id) && (
                        <img src={checkSvg} alt="Selected" />
                      )}
                    </button>
                    <p>{color[nameLangKey]}</p>
                  </div>
                ))}
            </div>
          </div>

        </div>

        <div className="w-full">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-[20px]">
            {productsSuccess && productsData?.result?.products?.length > 0 ? (
              productsData.result.products.map((item, index) => <OurproductMap item={item} key={index} />)
            ) : (
              <p>{langData.No_products_found}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
