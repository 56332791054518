import React, { useEffect, useState } from 'react';
import { icons } from '@utilits/icons';
import { useGetOneCategoryMutation } from '@services/AllApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActivesupmenu, setLoader } from '@Slice';

export default function Supmenu({ categoryData }) {
    const [activemenu, setActivemenu] = useState(0);
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.HomeSlice.lang)
    const langData = useSelector((state) => state.HomeSlice.langData)
    const apiToken = localStorage.getItem('apiToken')
    const [getOneCategory, { data: getOneCategoryData, isLoading: getOneCategoryLoading, isSuccess: getOneCategorySuc }] = useGetOneCategoryMutation()
    const navigate = useNavigate()

    const toggleMenu = async (menuId) => {
        if (activemenu === menuId) {
            setActivemenu(null);
        } else {
            setActivemenu(menuId);
            if (apiToken && !getOneCategoryData) {
                try {
                    await getOneCategory({
                        method: "category_details",
                        params: {
                            id: menuId
                        }
                    });
                } catch (error) {
                    console.error("Error fetching category:", error);
                }
            }

        }
    };

    useEffect(() => {
        getOneCategorySuc && dispatch(setLoader(!getOneCategorySuc))
    }, [getOneCategorySuc])



    const handleNavigate = (item) => {
        navigate(`/product/${item}`);
    };


    const NameLangKey = `name_${lang}`;


    return (
        <div onMouseLeave={() => dispatch(setActivesupmenu(false))} className='container z-50 mx-auto absolute left-[50%] translate-x-[-50%] bg-white'>
            <div className="px-5 py-7 rounded-xl shadow-2xl">
                <div className="flex justify-between items-center pb-3">
                    <h2 className='text-[24px]'>Menu</h2>
                    <button onClick={() => dispatch(setActivesupmenu(false))} className='p-1 bg-gray-100 rounded-full'>{icons.close}</button>
                </div>
                <div className="flex  justify-between border-y-[1px] border-gray-300 py-3">
                    {
                        categoryData?.map((item, index) => (
                            item.parent_id == 0 ?
                                <div onMouseLeave={() => toggleMenu('')} onMouseEnter={() => toggleMenu(item.id)} key={index} className="">
                                    <button onClick={() => handleNavigate(item.id)} className='flex items-center gap-3 text-[20px] leading-[30px]'>{item[NameLangKey]}
                                        {
                                            item.all_children.length > 0 &&
                                            <span className={`${activemenu == item.id ? 'rotate-[180deg] duration-300' : " duration-300"}`}>{icons.arrowdownblack}</span>
                                        }
                                    </button>
                                    {activemenu === item.id && item.all_children.length > 0 &&
                                        <ul className='grid gap-3 mt-4 cursor-pointer text-[#595959] text-[16px] leading-6'>
                                            {item.all_children?.map((child, childIndex) => (
                                                <li onClick={() => handleNavigate(child.id)}
                                                    key={childIndex}>{child[NameLangKey]}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                                : ""
                        ))
                    }
                </div>
                <div className="grid pt-3">
                    <h2 className='text-[24px] mb-3'>{langData.service}</h2>
                    <div className="flex gap-4 text-[16px]">
                        <button className='px-7 py-3 rounded-3xl bg-[#F5F5F5]'>{langData.putLogo}</button>
                        <button className='px-7 py-3 rounded-3xl bg-[#F5F5F5]'>{langData.clothingFitting}</button>
                        <button className='px-7 py-3 rounded-3xl bg-[#F5F5F5]'>{langData.delivery}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
