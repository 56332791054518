import React, { useEffect, useRef, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { icons } from '@utilits/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function OurproductMap({ item }) {
    const [activecount, setActivecount] = useState(false);
    const navigate = useNavigate();
    const carouselRef = useRef(null); // Ref to access carousel container

    const langData = useSelector((state) => state.HomeSlice.langData);
    const lang = useSelector((state) => state.HomeSlice.lang);

    const currency = (number, currency, lang = undefined) =>
        Intl.NumberFormat(lang, { style: "currency", currency }).format(number);
    const NameLangKey = `name_${lang}`;

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (activecount) {
                if (e.key === 'ArrowRight') {
                    const nextButton = carouselRef.current.querySelector('.control-next');
                    nextButton?.click();  // Simulate next button click
                }
                if (e.key === 'ArrowLeft') {
                    const prevButton = carouselRef.current.querySelector('.control-prev');
                    prevButton?.click();  // Simulate prev button click
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activecount]);

    // Handle preventing event propagation for the arrows and like button
    const handleArrowClick = (e) => {
        e.stopPropagation();
    };

    const handleLikeClick = (e) => {
        e.stopPropagation();
        // Implement like functionality here
    };

    return (
        <div
            className="relative p-[10px] productCauousel  rounded-xl hover:shadow-[0px_4px_14px_0px_#D3D3D340]"
            onMouseEnter={() => setActivecount(true)}
            onMouseLeave={() => setActivecount(false)}
            onClick={() => navigate(`/productDetail/${item.id}`)}
            ref={carouselRef}
        >
            <Carousel
                showArrows={true}
                autoPlay={false}
                interval={2000}
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
                infiniteLoop={true}
                swipeable={true}
                dynamicHeight={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button
                            type="button"
                            onClick={(e) => {
                                handleArrowClick(e); // Prevent click propagation
                                onClickHandler();
                            }}
                            title={label}
                            className={`control-prev absolute left-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}
                        >
                            {icons.prev}
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button
                            type="button"
                            onClick={(e) => {
                                handleArrowClick(e); // Prevent click propagation
                                onClickHandler();
                            }}
                            title={label}
                            className={`control-next absolute right-0 top-1/2 transform -translate-y-1/2 ${!activecount && 'hidden'}`}
                        >
                            {icons.next}
                        </button>
                    )
                }
            >
                {item?.files.length > 0 && item?.files.map(file => (
                    <img 
                    src={`https://backend.morobolsin.uz/${file.path}`} 
                    alt="" 
                    className="rounded-[12px] w-full h-[55vh] object-cover" 
                    loading="lazy" 
                />
                ))}
            </Carousel>

            <div className="flex items-center justify-between absolute top-4 w-full px-[10px]">
                {item?.attributes[1]?.id && (
                    <div className='flex items-center gap-[8px] bg-white px-[17px] py-[5px] rounded-[47px] text-[16px]'>
                        <span>{icons.foizgreen}</span>{item.attributes[1]?.value}%
                    </div>
                )}
                {activecount && (
                    <button
                        className='bg-white p-[8px] rounded-full mr-[15px]'
                        onClick={handleLikeClick} // Prevent click propagation
                    >
                        {icons.like}
                    </button>
                )}
            </div>

            <div className="flex lg:hidden gap-2 p-2">
                {item?.colors.length > 0 && item?.colors.map((color, index) => (
                    <div key={index} className={`border-2 border-[#D7D7D7] rounded-full flex items-center justify-center w-[32px] h-[32px]`}>
                        <div className={`rounded-full w-[30px] h-[29px]`} style={{ backgroundColor: color?.name_en }}></div>
                    </div>
                ))}
            </div>

            <div className="flex p-2 mt-2 cursor-pointer items-start justify-between">
                <div className="grid">
                    <h2 className='text-[14px] lg:mt-[16px] lg:text-[16px] font-light'>{item[NameLangKey]}</h2>
                    <p className='text-[16px] lg:text-[18px] font-medium'>
                        {item.price && currency(item.price, 'UZS').replace("UZS", "").replace("soʻm", "").replace(/,/g, " ").slice(0, -3).replace('.', " ")} {langData.sum}
                    </p>
                </div>
                <div className="hidden lg:flex gap-2">
                    {item?.colors.length > 0 && item?.colors.map((color, index) => (
                        <div key={index} className={`border border-[#D7D7D7] rounded-full flex items-center justify-center w-[20px] h-[20px]`}>
                            <div className={`rounded-full w-[18px] h-[18px]`} style={{ backgroundColor: color?.name_en }}></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurproductMap;
